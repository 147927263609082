<template>
  <div>
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-company-list'"
        :title="'Add company'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
      <v-form
        ref="form"
        lazy-validation
      >
        <v-row class="ma-0 pt-6">
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.name"
                  dense
                  outlined
                  :label="$t('Name')"
                  class="w-full"
                  :rules="[validator.required]"
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.referent_name"
                  dense
                  outlined
                  :label="$t('Referent')"
                  class="w-full"
                  :rules="[validator.required]"
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.address"
                  dense
                  outlined
                  :label="$t('Address')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.zip_code"
                  dense
                  outlined
                  :label="$t('Zip code')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.city"
                  dense
                  outlined
                  :label="$t('City')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.country"
                  dense
                  outlined
                  :label="$t('Country')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.email"
                  dense
                  outlined
                  :label="$t('Email')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.phone"
                  dense
                  outlined
                  :label="$t('Phone')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.company_identification_number"
                  dense
                  outlined
                  :label="$t('ID number')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-select
                  v-model="company.status"
                  dense
                  outlined
                  :items="statusOptions"
                  item-value="key"
                  value="active"
                  :item-text="item => $t(item.name)"
                  :label="$t('Status')"
                  class="w-full"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-checkbox
                  v-model="company.is_client"
                  :label="$t('Client')"
                  outlined
                  dense
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                v-if="companyId && company.is_client"
                cols="12"
                md="6"
                lg="6"
              >
                <router-link
                  :to="{ name: 'admin-payment-list', params: { companyId: companyId } }"
                  class="v-btn secondary pa-2"
                >
                  {{ $t('Payment history') }}
                </router-link>
              </v-col>
            </v-row>
          </v-card-text>
        </v-row>
      </v-form>
      <StandardCardHeader
        :go-to-link="'admin-company-list'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
    </v-card>
    <v-snackbar
      v-model="snackbar"
    >
      {{ $t('Data successfully saved') }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('Close' ) }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { required } from '@core/utils/validation'
import axiosIns from '@axios'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'

export default {
  components: { StandardCardHeader },
  data() {
    const companyId = null
    const snackbar = false
    const loading = true
    const company = {
      status: 'active',
    }
    const statusOptions = this.$store.state.baseStatuses

    return {
      companyId,
      snackbar,
      loading,
      company,
      statusOptions,
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
      validator: {
        required,
      },
    }
  },

  methods: {
    save() {
      if (this.$refs.form.validate()) {
        axiosIns({
          method: this.companyId ? 'PUT' : 'POST',
          data: this.company,
          url: this.companyId ? `/admin/companies/${this.companyId}` : '/admin/companies',
        })
          .then(res => {
            this.snackbar = true
            this.companyId = res.data.company.id
          })
          .catch()
      }
    },
  },
}
</script>

<style lang="scss">
</style>
